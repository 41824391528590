import millify from "millify";
import moment from "moment";

// classic grafana palette
export const COLORS = [
    '#7EB26D', // 0: pale green
    '#EAB839', // 1: mustard
    '#6ED0E0', // 2: light blue
    '#EF843C', // 3: orange
    '#E24D42', // 4: red
    '#1F78C1', // 5: ocean
    '#BA43A9', // 6: purple
    '#705DA0', // 7: violet
    '#508642', // 8: dark green
    '#CCA300', // 9: dark sand
    '#447EBC',
    '#C15C17',
    '#890F02',
    '#0A437C',
    '#6D1F62',
    '#584477',
    '#B7DBAB',
    '#F4D598',
    '#70DBED',
    '#F9BA8F',
    '#F29191',
    '#82B5D8',
    '#E5A8E2',
    '#AEA2E0',
    '#629E51',
    '#E5AC0E',
    '#64B0C8',
    '#E0752D',
    '#BF1B00',
    '#0A50A1',
    '#962D82',
    '#614D93',
    '#9AC48A',
    '#F2C96D',
    '#65C5DB',
    '#F9934E',
    '#EA6460',
    '#5195CE',
    '#D683CE',
    '#806EB7',
    '#3F6833',
    '#967302',
    '#2F575E',
    '#99440A',
    '#58140C',
    '#052B51',
    '#511749',
    '#3F2B5B',
    '#E0F9D7',
    '#FCEACA',
    '#CFFAFF',
    '#F9E2D2',
    '#FCE2DE',
    '#BADFF4',
    '#F9D9F9',
    '#DEDAF7',
  ];

export const dateFormatter = (date) => {
    return moment(date).format("YYYY-MM-DD HH:00");
}

export const countFormatter = (count) => {
    return millify(count, {precision: 2});
}

export const durationFormatter = (duration) => {
    return moment.duration(duration * 1000).humanize();
}

export const piechartLegendFormatter = (v, entry, index) => {
    const {value, name, percent} = entry.payload;
    const percentage = asPercentage(percent);
    return `${name}: ${percentage}% (${countFormatter(value)})`;
}

export const piechartLabelRenderer = (props) => {
    const {x, y, fill, textAnchor, name, percent} = props;
    const percentage = asPercentage(percent);
    return (
        <text x={x} y={y} fill={fill} alignmentBaseline="middle" textAnchor={textAnchor}>{name} ({percentage}%)</text>
    );
} 

export const asPercentage = (percentage) => {
    return (percentage * 100).toFixed(2);
}
